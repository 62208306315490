<template>
  <div ref="exper" class="exper">
    <van-nav-bar title="評估結果" left-arrow fixed :z-index="1000" class="qjc-nav-bar"
      @click-left="$router.push('/user/history')">
      <van-image :src="require('@/assets/images/home.png')" slot="right" width="0.32rem" height="0.32rem"
        style="vertical-align: middle;" @click="$router.push('/')" />
    </van-nav-bar>
    <div class="banner">

    </div>

    <div class="result">
      <div class="branch_wrap">
        <div class="branch_score">
          <div class="branch_content">
            <div class="branch_item_title">
              健康評分
              <i @click="scoreTip1 = !scoreTip1" class="iconfont iconyiwen1 z_index9 qjc-relative">
                <div v-if="scoreTip1" class="score_tip_box qjc-bg-fff qjc-absolute">
                  <span
                    class="score_tip_text">米因以大數據為基礎，綜合個人的當前健康狀況、潛在疾病風險和未來健康風險進行整體評分，給出百分制的「健康分」，以求儘可能對整體健康進行量化評估，既可用於表示個人健康狀況，也可作為個人健康管理的目標和評價標準。
                    <br><span class="score_tip_span">對於個人而言，分數越高則相對越健康，健康分不適用於85歲以上的長者。</span></span>
                  <div class="arrow"></div>
                </div>
              </i>
            </div>
            <div class="score_info_top"
              :style="{ '--infoLeft': scoreVal > 3 ? scoreVal - 3 + '%' : scoreVal + 2 + '%' }">
              <div class="score_wrap">
                <div class=""> {{ res.base.age > 85 ? '健康分不適用於85歲以上的長者' : '你的健康評分' }}</div>
                <div class="score_info_top_info" v-if="res.base.age <= 85">
                  <span
                    :class="scoreVal <= 59 ? 'score_color3 score_val' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_val' : 'score_color1 score_val'">{{
                      scoreVal }}</span><span
                    :class="scoreVal <= 59 ? 'score_color3 score_unit' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_unit' : 'score_color1 score_unit'">
                    分({{ scoreVal <= 59 ? '欠佳' : (scoreVal > 59 && scoreVal <= 89) ? '良好' : scoreVal > 89 ? '優等' :
                      '' }})</span>
                  <img v-if="scoreVal <= 59" class="xiao_icon" src="@/assets/images/health_3.png" />
                  <img v-else-if="scoreVal > 59 && scoreVal <= 89" class="xiao_icon"
                    src="@/assets/images/health_2.png" />
                  <img v-else-if="scoreVal > 89" class="xiao_icon" src="@/assets/images/health_1.png" />
                </div>
              </div>
            </div>
            <div class="score_line">
              <div class="score_one_bg"></div>
              <div class="score_d1"></div>
              <div class="score_two_bg"></div>
              <div class="score_d2"></div>
              <div class="score_three_bg"></div>
              <div class="score_line_top">
                <div class="score_one" :style="{ width: (scoreVal <= 59 ? scoreVal + '%' : '59%') }"></div>
                <div v-if="scoreVal > 59" class="score_two"
                  :style="{ width: ((scoreVal > 59 && scoreVal <= 89) ? scoreVal - 59 + '%' : '30%') }"></div>
                <div v-if="scoreVal > 89" class="score_three"
                  :style="{ width: (scoreVal > 89 ? scoreVal - 89 + '%' : '11%') }"></div>
                <div
                  :class="scoreVal <= 59 ? 'score_circle3' : (scoreVal > 59 && scoreVal <= 89) ? 'score_circle2' : scoreVal > 89 ? 'score_circle1' : ''"
                  :style="{ left: scoreVal - 2 + '%' }"></div>
              </div>
            </div>
            <div class="score_text">健康分等級：100-90分以上視為「優等」；89-60分視作「良好」；59-1分視為「欠佳」。</div>
          </div>
        </div>
      </div>
      <div class="branch_wrap">
        <div class="branch_health branch_score">
          <div class="branch_content">
            <div class="branch_item_title">
              人群分類
            </div>

            <div class="branch_health_list">
              <div class="health-card HEA1" v-if="res.base.categ == 'HEA1'">
                <div class="card-label">妳屬於</div>
                <div class="card-value">健康人群</div>
              </div>
              <div class="health-card HEA2" v-else-if="res.base.categ == 'HEA2'">
                <div class="card-label">妳屬於</div>
                <div class="card-value">亞健康人群</div>
              </div>
              <div class="health-card HEA3" v-else-if="res.base.categ == 'HEA3'">
                <div class="card-label">妳屬於</div>
                <div class="card-value">醫療需求人群</div>
              </div>
            </div>

            <van-grid :column-num="3" :border="false" v-if="0">
              <van-grid-item class="qjc-nowrap">
                <span>身高：<b class="qjc-c-dark">{{ res.base.height }}cm</b></span>
              </van-grid-item>
              <van-grid-item class="qjc-nowrap">
                <span>體重：<b class="qjc-c-dark">{{ res.base.weight }}kg</b></span>
              </van-grid-item>
              <van-grid-item class="qjc-nowrap">
                <span>體脂率：<b class="qjc-c-dark">{{ res.base.fat ? res.base.fat : '--' }}%</b></span>
              </van-grid-item>
              <van-grid-item class="qjc-nowrap">
                <span>BMI：<b class="qjc-c-dark">{{ res.base.bmi ? res.base.bmi : '--' }}</b></span>
              </van-grid-item>
              <van-grid-item class="qjc-nowrap">
                <span>腰圍：<b class="qjc-c-dark">{{ res.base.waist ? res.base.waist : '--' }}cm</b></span>
              </van-grid-item>
              <van-grid-item class="qjc-nowrap">
                <span>臀圍：<b class="qjc-c-dark">{{ res.base.hip ? res.base.hip : '--' }}cm</b></span>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
      </div>
      <div class="person-body-info">
        <van-grid class="person qjc-bg-fff" :column-num="3" :border="false">
          <van-grid-item>
            <dl>
              <dt>身高</dt>
              <dd class="qjc-c-primary"><b>{{ res.base.height }}</b>cm</dd>
            </dl>
            <dl>
              <dt>
                體重
              </dt>
              <dd class="qjc-c-primary"><b>{{ res.base.weight }}</b>kg</dd>
            </dl>
          </van-grid-item>
          <van-grid-item>
            <van-image width="1.84rem" height="5.5rem"
              :src="this.res.base.sex == 1 ? require('@/assets/images/man.png') : (this.res.base.sex == 2 ? require('@/assets/images/wuman.png') : null)" />
          </van-grid-item>
          <van-grid-item>
            <dl>
              <dt>腰圍</dt>
              <dd class="qjc-c-primary"><b>{{ res.base.waist }}</b>cm</dd>
            </dl>
            <dl>
              <dt>臀圍</dt>
              <dd class="qjc-c-primary"><b>{{ res.base.hip || '--' }}</b>cm</dd>
            </dl>

          </van-grid-item>
        </van-grid>
      </div>
      <div class="health-result" v-if="res.m05">
        <div class="health-result-subtitle">體型評估</div>
        <div class="health-result-content">
          <body-commit-card :range="range" :value="Number(res.base.bmi)" name="BMI" title="BMI">
            <template slot="tip">
              <div class="tip-content">
                <div class="tip-title">PLM同族群值</div>
                <div>與妳同年齡、同性別的人，他們的BMI平均值為：<span>{{ res.m05.bmi_g[2] }}</span></div>
                <div>和他們相比，妳的BMI：<span class="tip-content-tag">{{ res.m05.bmi_g[1] }}</span></div>
              </div>
            </template>
          </body-commit-card>
        </div>
        <div class="health-result-content">
          <body-commit-card :range="whrRange" :value="Number(res.base.whr)" name="WHR" title="腰臀比WHR">
            <template slot="tip">
              <div class="tip-content">
                <div class="tip-title">PLM同族群值</div>
                <div>與妳同年齡、同性別的人，他們的WHR平均值為：<span>{{ res.m05.whr_g[2] }}</span></div>
                <div>和他們相比，妳的WHR：<span class="tip-content-tag">{{ res.m05.whr_g[1] }}</span></div>
              </div>
            </template>
          </body-commit-card>
        </div>
        <div class="health-result-content">
          <body-commit-card :range="range" :value="Number(res.base.fat)" name="BFR" title="體脂率BFR">
            <template slot="tip">
              <div class="tip-content">
                <div class="tip-title">PLM同族群值</div>
                <div>與妳同年齡、同性別的人，他們的BFR平均值為：<span>{{ res.m05.fat_g[2] }}</span></div>
                <div>和他們相比，妳的BFR：<span class="tip-content-tag">{{ res.m05.fat_g[1] }}</span></div>
              </div>
            </template>
          </body-commit-card>
        </div>
        <div class="health-result-body">
          <div class="result-body-title">體型</div>
          <div>與你同性別同年齡的人群相比，</div>
          <div>你的BMI<span>適中</span>，體脂率<span>適中</span></div>
          <div>因此，你的體型與你同性別同年齡的人群相比，屬於</div>
          <div class="result-body-type">{{ res.m05.body_g[1] }}</div>
          <div class="result-body-tip">
            {{ res.m05.body_g[2] }}
          </div>
        </div>

        <div class="advertisement-box">
          <img src="@/assets/images/recommend-product.png" alt="">
        </div>

        <div class="health-result-subtitle">百歲概率及排名(90歲/100歲)</div>

        <div class="health-age-forecast">
          <div>
            <div class="heal-content-card">
              <div>
                <div class="label">90歲概率</div>
                <div class="value">{{ res.m01.lift_rates_90 }}<span>%</span></div>
              </div>
              <div>
                <div class="label">90歲概率排名</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m01.rank_lift_rates_90 }}<span>名</span></p>

                  <img v-else @click="showForecast" src="@/assets/images/report/unlock.png" alt="">

                </div>
              </div>
            </div>
            <div class="age-forecast-unlock" v-if="0">
              <div>
                <div class="unlock-label">超值解鎖</div>
                <div class="unlock-btn">
                  <div><span>$</span>19.9</div>
                  <div>立即解鎖</div>
                </div>
              </div>

            </div>
          </div>
          <div>
            <div class="heal-content-card">
              <div>
                <div class="label">百歲概率</div>
                <div class="value">{{ res.m01.lift_rates_100 }}<span>%</span></div>
              </div>
              <div>
                <div class="label">百歲概率排名</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m01.rank_lift_rates_100 }}<span>名</span></p>

                  <img v-else @click="showForecast" src="@/assets/images/report/unlock.png" alt="">

                </div>
              </div>
            </div>
            <div class="age-forecast-unlock" v-if="0">
              <div class="unlock-label">超值解鎖</div>
              <div class="unlock-btn">
                <div><span>$</span>19.9</div>
                <div>立即解鎖</div>
              </div>
            </div>
          </div>
        </div>
        <div class="health-age-forecast">
          <div class="">
            <div>
              <div>
                <div class="label">預期壽命</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m01.pre_lift }}<span>歲</span></p>

                  <img v-else @click="showForecast" src="@/assets/images/report/unlock.png" alt="">
                </div>
              </div>
              <div>
                <div class="label">預期壽命排名</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m01.rank_pre_lift }}<span>名</span></p>

                  <img v-else @click="showForecast" src="@/assets/images/report/unlock.png" alt="">
                </div>
              </div>
            </div>
            <div class="age-forecast-unlock" v-if="!unlock.forecast">
              <div>
                <div class="unlock-label">超值解鎖</div>
                <div class="unlock-btn" @click="showForecast">
                  <div><span>$</span>19.9</div>
                  <div>立即解鎖</div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="health-result-system-plan">
          <div class="health-result-title" v-if="res.base.categ == 'HEA1'">系統疾病風險及養生飲食運動方案</div>
          <div class="health-result-title" v-else-if="res.base.categ == 'HEA2'">慢病風險明細</div>
          <div class="health-result-title" v-else-if="res.base.categ == 'HEA3'">急重症風險</div>
          <div class="health-result-paragraph" v-if="res.base.categ == 'HEA1'">
            妳的身體狀況出現了一點小問題，可能會患有下列疾病，需要提高警惕哦~
          </div>
          <div class="system-plan-box" v-for="(item) in res.m07.dis_risk" :key="item.dis">
            <div class="header">
              <div class="rank">風險排序：<span>NO.{{ item.no }}</span></div>
              <div class="title">{{ item.devg }}</div>
            </div>

            <div v-if="unlock.risk" class="system-plan-content">
              <div :id="`dis-chart-${item.dis}`" class="plan-content-chart">
              </div>
              <div class="health-result-paragraph">
                <p><span>主要症狀：</span>{{ item.desc }}</p>
              </div>
              <div class="system-plan-ai-tip">
                <div class="ai-pic">
                  <img src="@/assets/images/report/ai-pic.png" alt="">
                </div>
                <div class="ai-content">
                  <div class="ai-text">
                    妳好，我是妳的AI健康小助手
                    這裡有一份關於預防<span>{{ item.devg }}</span>
                    的養生飲食運動方案~
                  </div>
                  <div class="ai-btn">點擊查看</div>
                </div>
              </div>
            </div>
            <div class="system-plan-content" v-else @click="showUnlockRiskChart">
              <div class="plan-content-text">系統風險明細及養生飲食運動方案</div>
              <img src="@/assets/images/report/unlock.png" alt="">
            </div>
          </div>
        </div>

        <div class="health-improve-plan">
          <div class="health-result-title">
            健康改善方案
          </div>
          <div class="health-result-subtitle">
            計劃時長
          </div>
          <div class="improve-plan-box">
            <div class="health-value-card">
              <div class="label">計劃時長</div>
              <div class="value">
                <p v-if="unlock.forecast">{{ res.m12.weeks[res.m12.weeks.length - 1] }}<span>周</span></p>

                <img v-else @click="showForecast" src="@/assets/images/report/unlock.png" alt="">
              </div>
            </div>
            <div class="age-forecast-unlock" v-if="!unlock.forecast">
              <div>
                <div class="unlock-label">超值解鎖</div>
                <div class="unlock-btn" @click="showForecast">
                  <div><span>$</span>19.9</div>
                  <div>立即解鎖</div>
                </div>ß
              </div>

            </div>
          </div>
          <div class="health-result-subtitle">
            體重改善
          </div>
          <div class="improve-plan-before-after">
            <div class="plan">
              <div class="health-value-card">
                <div class="label">當前體重</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m12.sugweight[0] }}<span>kg</span></p>
                  <img v-else src="@/assets/images/report/unlock.png" alt="" @click="showForecast">
                </div>
              </div>
            </div>
            <div class="arrow">
              <img src="../../assets/images/report/arrow-right.svg" alt="arrow">
            </div>
            <div class="plan">
              <div class="health-value-card">
                <div class="label">目標體重</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m12.sugweight[1] }}<span>kg</span></p>
                  <img v-else @click="showForecast" src="@/assets/images/report/unlock.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="health-result-subtitle">
            腰臀比改善
          </div>
          <div class="improve-plan-before-after">
            <div class="plan">
              <div class="health-value-card">
                <div class="label">當前腰臀比</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m12.sugwhr[0] }}<span></span></p>
                  <img v-else src="@/assets/images/report/unlock.png" alt="" @click="showForecast">
                </div>
              </div>
            </div>
            <div class="arrow">
              <img src="../../assets/images/report/arrow-right.svg" alt="arrow">
            </div>
            <div class="plan">
              <div class="health-value-card">
                <div class="label">目標腰臀比</div>
                <div class="value">
                  <p v-if="unlock.forecast">{{ res.m12.sugwhr[1] }}<span></span></p>
                  <img v-else @click="showForecast" src="@/assets/images/report/unlock.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="advertisement-box">
          <img src="@/assets/images/recommend-product.png" alt="">
        </div>

        <div class="health-future-medical">
          <div class="health-result-title">未來醫療費用預測</div>
          <div class="health-result-paragraph">
            基於妳的總體健康狀況和疾病風險，預測妳的未來一年醫療費用。
          </div>
          <div class="health-future-chart-box">
            <div id="health-future-chart">
            </div>
          </div>
        </div>

      </div>
    </div>

    <van-popup v-model="isUnlockRiskShow">
      <div class="unlock-risk-content">
        <div class="unlock-risk-title">想瞭解更多健康風險？</div>
        <div class="unlock-risk-desc">全部解鎖系統風險</div>
        <div class="unlock-risk-desc">個人專屬健康改善方案</div>
        <div class="unlock-risk-price">
          <div class="now">$20.99</div>
          <div class="pre">
            <p>原價:<span>$40.99</span></p>
          </div>
        </div>
        <div class="unlock-risk-btn" @click="unlockRisk">立即解鎖</div>
        <img src="@/assets/images/close-popup.png" @click="isUnlockRiskShow = false" class="unlock-close" alt="">
      </div>
    </van-popup>

    <van-popup v-model="isUnlockForecast">
      <div class="unlock-forecast-content">
        <div class="unlock-forecast-title">解鎖你的健康密碼</div>
        <div class="unlock-forecast-desc">
          <div>預期壽命及排名</div>
          <div>$3.2</div>
        </div>
        <div class="unlock-forecast-detail">
          <div class="cate">
            <div class="cate-title">
              <div>全部解鎖</div>
            </div>
            <div class="cate-name">預期壽命及排名</div>
            <div class="cate-name">計畫時長</div>
            <div class="cate-name">體重及腰臀比</div>
          </div>
          <div class="price" @click="unlockForecast">
            <img class="price-hot" src="@/assets/images/jkzc/price-hot.png" alt="">
            <div class="now">$20.99</div>
            <div class="pre">
              <p>原價:<span>$40.99</span></p>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import Vue from 'vue'
import { NavBar, Grid, GridItem, Image, Toast, Popup } from 'vant'
Vue.use(NavBar)
  .use(Grid)
  .use(GridItem)
  .use(Image)
  .use(Toast)
  .use(Popup);

import { toggleClass, accMul } from '@/js/utils.js'

import { Base64 } from 'js-base64'


import bodyCommitCard from '@/components/custom/bodyCommitCard.vue'

// 獲取結果數據
import { getReportDetail } from '@/js/axios.js'

// html2canvas生成長圖
import html2canvas from 'html2canvas'
var echarts = require('echarts');

export default {
  name: 'resultExperience',
  components: {
    bodyCommitCard
  },
  data () {
    return {
      isLoading: true,//正在加載
      progress: 0,//進度值 0-100
      nowProgress: 50,//當前進度

      timer: '',//長按保存定時器
      saveImgSrc: '',//頁面存為長圖的src

      id: this.$route.params.id,// 當前結果id
      scoreVal: '',
      scoreTip: false, //健康分 tip
      scoreTip1: false,
      res: {
        amount: '--',//價格
        name: '--',//名稱
        base: {
          health_type: '',//所屬人群
          height: '',//身高
          weight: '',//體重
          fat: '',//體脂率
          bust: '',//胸圍
          waist: '',//腰圍
          hip: '',//臀圍
          sex: '',//男女 1男 2女
        },
        m01: {
          pre_lift: '',//預期壽命
          lift_rates: '',//百歲概率
        },
        m02: {
          realage: ''
        },
        m05: {
          body_g: [],
          bmi_g: [],
          whr_g: [],
          fat_g: []
        },
        m07: {
          dis_risk: []
        },
        m10: {
          person_yearsfee: ''
        },
        m12: {
          sugweight: [],
          sugwhr: []
        },
        m14: {
          bmr: '',
          c0: '',
          bmr_df: '',
          c21: '',
          c12: '',
          c31: '',
          c41: '',
          c51: ''
        },
        m15: ''//只有四高 概率最大的一個
      },

      more: false, //瞭解更多

      unlock: {
        rank90: false,
        rank100: false,
        forecastAge: false,
        forecastAgeRank: false,
        dis_risk: [],
        planTime: false,
        planWeight: false,
        planWhr: false,
        forecast: false,
        risk: false,

      },

      isUnlockRiskShow: false, //解鎖風險提示框是否顯示
      isUnlockForecast: false, //解鎖風險提示框是否顯示

      range: [
        {
          key: 'low',
          name: "偏低",
          min: 0,
          max: 18.5,
          color: "#f4cd40"
        },
        {
          key: 'normal',
          name: "適中",
          min: 18.5,
          max: 23,
          color: "#3fd845"
        },
        {
          key: 'high',
          name: "偏高",
          min: 23,
          max: 100,
          color: "#ff5c5c"
        },
      ],
      range: [
        {
          key: 'low',
          name: "偏低",
          min: 0,
          max: 20,
          color: "#f4cd40"
        },
        {
          key: 'normal',
          name: "適中",
          min: 20,
          max: 27,
          color: "#3fd845"
        },
        {
          key: 'high',
          name: "偏高",
          min: 27,
          max: 100,
          color: "#ff5c5c"
        },
      ],
      whrRange: [

        {
          key: 'normal',
          name: "適中",
          min: 0,
          max: 0.88,
          color: "#3fd845"
        },
        {
          key: 'high',
          name: "偏高",
          min: 0.88,
          max: 2,
          color: "#ff5c5c"
        },
      ],
      bodyTypes: {
        thin: {
          name: '精實',
          content: '妳的身體結實，是適量鍛煉的成果，請堅持鍛煉，保持飲食控制，避免脂肪堆積導致身材走樣'
        },
        normal: {
          name: '適中',
          content: 'BMI適中，體脂率適中。體重過輕或過重都對健康不利，妳的體重剛剛好，請繼續保持，合理飲食，適量運動，健康常在。'
        },
        fat: {
          name: "隱形肥胖",
          content: "BMI適中，體脂率偏高。妳的體重雖然正常，體型也看似勻稱，但身體內部和內臟周圍已經堆積了一些脂肪，還請多加註意，過多的脂肪可能會對妳的健康造成不良的影響，建議妳控制飲食並維持適量運動。"
        }
      },
      bodyType: "normal",

      charts: {

      }

    }
  },
  watch: {
    res () {//如果接口已獲取數據，進度100%
      if (this.progress < 50) {
        this.nowProgress = 100;
      } else {
        this.nowProgress = 100;
        this.progressBar();
      }
    },
    progress (val) {//進度條100結束過渡
      if (val == 100) {
        this.isLoading = false;
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      //進度條
      this.progressBar();

      // 獲取結果數據
      this.getResult();
    })

  },
  methods: {
    //進度條結束生成全內容長圖
    creatImg () {
      this.$nextTick(() => {
        html2canvas(this.$refs.exper, {
          useCORS: true,
          logging: false,
          ignoreElements: (element) => {
            if (element.parentNode.className.match(/iconfont iconyiwen1/) || element.className == 'suspend') {
              return true;
            }
          }
        }).then(canvas => {
          this.saveImgSrc = canvas.toDataURL();
        });
      })
    },

    //進度條 參數：當前進度
    progressBar () {
      var speed = this.progress > 40 && this.progress < 49 ? (10 - (50 - this.progress)) * 50 : 25;//速率(減速再加速)

      if (this.progress < this.nowProgress) {
        ++this.progress;
        setTimeout(() => {
          this.progressBar();
        }, speed);
      }
    },

    getResult () {
      getReportDetail({ id: this.id }).then(data => {
        if (data.code == 200) {

          data = JSON.parse(Base64.decode(data.data)); // 解碼
          if (data.base.categ == "HEA1") {
            data.base.health_type = 1
          } else if (data.base.categ == "HEA2") {
            data.base.health_type = 2
          } else if (data.base.categ == "HEA3") {
            data.base.health_type = 3
          }
          this.res = data;
          if (Array.isArray(data.m07.dis_risk)) {
            setTimeout(() => {
              data.m07.dis_risk.forEach(item => {
                this.unlock.dis_risk.push(false)
                this.drawChartDis(item)
              })
            }, 300)

          }
          if (data.m10.person_yearsfee) {
            this.drawChart2(Number(data.m10.person_yearsfee))
          }
          this.scoreVal = data.base.mlev ? data.base.mlev : 0;

          let dis_risk = data.m07.dis_risk;

        } else {
          Toast.fail('未查找到報告');
          this.$router.replace('/user');
        }

      })
    },

    // 懸浮按鈕點擊事件
    suspendClick () {
      this.$router.push('/question');
    },

    describe (e) {//只作用於疑問icon圖標
      var target = e.target;
      if (target.className.indexOf('iconyiwen1') != -1) {
        toggleClass(target.children[0], 'show');
      }
    },

    arrMax (arr) {
      var max = arr[0];//默認取第一個
      for (var i = 1; i < arr.length; i++) {//從第二個開始篩選
        arr[i] > max ? max = arr[i] : '';
      }
      return max;
    },//數組中最大值

    drawChartDis (item) {

      const option = {
        color: ['#6883FB', '#65D448'],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['個人患病概率', '同族群患病概率'],
          icon: 'roundRect',
          textStyle: {
            color: '#777F8F',
            fontSize: 12
          },
          itemWidth: 10,
          itemHeight: 4,
          itemGap: 20
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['第一年', '第二年', '第三年', '第四年', '第五年'],
          axisLabel: {
            color: '#777F8F',
            interval: 0
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: 'true',
            lineStyle: {
              color: '#f5f5f5'
            }
          },
        },
        yAxis: {
          name: '%',
          type: 'value',
          axisLabel: {
            color: '#777F8F'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#f5f5f5'
            }
          }
        },
        series: [
          {
            name: '個人患病概率',
            type: 'line',
            data: item.person_rates.map(item => Number(item)),
            label: {
              position: 'top',
              show: true
            },
          },
          {
            name: '同族群患病概率',
            type: 'line',
            data: item.compare_rates.map(item => Number(item)),
            label: {
              position: 'top',
              show: true
            },
          },

        ]
      };

      this.drawChat(`dis-chart-${item.dis}`, option)

    },
    drawChart2 (fee) {

      const option = {


        legend: {
          icon: 'roundRect',
          textStyle: {
            color: '#777F8F',
            fontSize: 12
          },
          top: 10,
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 10,
          data: ['未來醫療費用預測']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },


        // xAxis: {
        //   type: 'category',
        //   boundaryGap: false,
        //   data: ['第一年'],

        // },
        // yAxis: {
        //   type: 'value',
        //   name: '￥',

        // },
        xAxis: {
          type: 'category',
          nameTextStyle: {
            color: '#777F8F'
          },
          axisLabel: {
            color: '#777F8F'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: ['第一年'],

        },
        yAxis: {
          name: '￥      ',
          nameTextStyle: {
            color: '#777F8F'
          },
          axisLabel: {
            color: '#777F8F'
          },
          axisLine: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#00000010'
            }
          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '未來醫療費用預測',
            type: 'bar',
            stack: 'Total',
            data: [fee],
            barWidth: 12,
            label: {
              show: true,
              position: 'top',
              formatter: '{c} 元'

            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,//漸變色在下面修改，這裏是透明度
                [{
                  offset: 0,
                  color: 'rgba(83, 131, 255, 1)',
                  borderRadius: 8
                },
                {
                  offset: 1,
                  color: 'rgba(161, 188, 255, 1)',

                }
                ]
              ),
              barBorderRadius: 8
            },
          },


        ]
      };

      this.drawChat('health-future-chart', option)

    },


    drawChat (el, option) {
      const that = this
      const element = document.querySelector(`#${el}`)
      if (!element) {
        return
      }
      this.charts[el] = echarts.init(element);
      // option.animationDuration = 1000;
      this.charts[el].setOption(option, true);
      window.onresize = () => {
        Object.entries(that.charts).forEach(([key, chart]) => {
          if (chart) {
            chart.resize();
          }
        })
      }
    },// 繪製圖表通用

    showDisRisk (index) {
      this.unlock.dis_risk = this.unlock.dis_risk.map((item, i) => {
        if (i == index) {
          return true
        } else {
          return false
        }
      })
      setTimeout(() => {
        this.drawChartDis(this.res.m07.dis_risk[index])
      }, 300)

    },
    isLocked (risk, index) {
      const bol = risk[index]
      return bol
    },
    showUnlockRiskChart () {
      this.isUnlockRiskShow = true;
    },
    unlockRisk () {
      this.isUnlockRiskShow = false;
      this.unlock.risk = true;
      console.log(this.res);

      setTimeout(() => {
        this.res.m07.dis_risk.forEach(item => {
          this.unlock.dis_risk.push(false)
          this.drawChartDis(item)
        })
      }, 300)
    },
    showForecast () {
      this.isUnlockForecast = true;
    },
    unlockForecast () {
      this.isUnlockForecast = false;
      this.unlock.forecast = true
    }
  }
}
</script>

<style scoped>
.branch_wrap>>>.van-grid-item__content {
  height: 0.56rem;
  text-align: left;
  padding: 0;
  background-color: #f5f6fa;
  border-radius: 0.28rem;
}

.branch_wrap>>>.van-grid-item {
  padding: 0.08rem 0.08rem;
  overflow: auto;
}

.branch_wrap>>>.van-grid {
  margin: 0.4rem 0 0.2rem 0;
}

.person>>>.van-grid-item__content {
  padding: 0;
}


.van-popup {
  overflow-y: visible;
  background-color: transparent;
}



.illness .sort>>>img {
  width: 0.58rem;
  margin-left: 0.12rem;
}
</style>
<style lang="scss" scoped>
@import "../../assets/iconfont/iconfont.css";

.exper {
  padding: 0 0 0.63rem;
  background-color: #f5f6fa;

  .van-nav-bar {
    z-index: 10000 !important;
  }

  // 多次使用樣式
  .fonts-40 {
    font-size: 0.4rem;
  }

  .c-red {
    color: #fa7893;
  }

  // banner廣告位
  .banner {
    height: 0.88rem;
    background-color: transparent;
  }

  // 疑問幫助
  .group_icon {
    color: #b8c0cc;
    font-size: 1em;
    z-index: 1000;

    div {
      top: -100%;
      left: -0.32rem;
      width: 5.28rem;
      text-align: left;
      border-radius: 0.08rem;
      padding: 0.18rem 0.24rem;
      line-height: 0.36rem;
      background-color: rgba(38, 40, 52, 0.6);
      transform: translateY(-100%);
      box-shadow: 0 0.06rem 0.12rem 0 rgba(218, 220, 227, 1);
      opacity: 0;
      transition: all 1s;
      z-index: 100;

      //顯示描述  默認隱藏
      &.show {
        opacity: 1;
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 0.4rem;
        content: "";
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        background-color: #7d7e85;
        transform: rotateZ(45deg) translateY(50%);
        z-index: 30;
      }

      // 彈出位置 默認靠右
      &.center {
        left: -3rem;

        &::after {
          left: 3.07rem;
        }
      }

      &.left {
        left: -4.7rem;

        &::after {
          left: 4.77rem;
        }
      }
    }
  }

  .van-nav-bar {
    background-color: #f5f7fa;

    .van-nav-bar__right {
      display: flex;
      align-items: center;
      height: 100%;

      .try-again {
        color: #777f8f;
        font-size: 0.2rem;
        line-height: 0.24rem;
      }
    }
  }

  .result {
    position: relative;
    width: 100%;
    color: #777f8f;
    padding: 0.24rem 0;

    &>div {}

    .person-body-info {
      position: relative;
      width: 100%;
      padding: 0 0.24rem;
      position: relative;
    }

    // 個人信息
    .infomation {
      padding: 1rem 0 0.8rem;
      background: url(../../assets/images/di.png) no-repeat;
      background-size: 100% 100%;

      .danger {
        width: 2.51rem;
        height: 2.24rem;
        margin: 0 auto;
        padding-top: 0.8rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &.jiankang {
          background-image: url(../../assets/images/jiankang.png);
        }

        &.yajiankang {
          background-image: url(../../assets/images/yajiankang.png);
        }

        &.yiliaoxuqiu {
          background-image: url(../../assets/images/yiliaoxuqiu.png);
        }

        .danger-val {
          font-size: 0.48rem;
        }

        dt {
          font-size: 0.2rem;
          margin-top: 0.3rem;
        }
      }

      .van-grid {
        margin-top: 0.56rem;
        padding: 0 0.3rem;

        .van-grid-item {
          padding: 0.08rem 0.08rem;
          overflow: auto;
        }
      }

      .hint {
        font-size: 0.2rem;
        color: #777f8f;
        margin-top: 0.06rem;
      }
    }

    .person {
      padding: 0 0.1rem;

      dl {
        margin: 1rem auto;

        dt {
          color: #999999;
        }

        b {
          font-size: 0.42rem;
        }
      }
    }

    // 通用標題樣式
    .module-title {
      position: relative;
      font-size: 0.36rem;
      font-weight: 600;
      color: #40444d;
      text-align: left;
      margin-bottom: 0.28rem;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1.94rem;
        height: 0.16rem;
        background: linear-gradient(to right,
            rgba(102, 129, 250, 1),
            rgba(102, 129, 250, 0));
        border-radius: 0.08rem 0.08rem 0 0;
        z-index: -1;
      }

      &+p {
        font-weight: 400;
        line-height: 0.44rem;
      }
    }



    // 重症預警(醫療需求者) 慢病風險(亞健康) 系統風險(健康)
    // 四高風險(亞健康)樣式相同
    .illness,
    .three-hights {
      margin-bottom: 1.17rem;

      &>div {
        margin-bottom: 0.69rem;

        &>dl {
          // background-color: #F5F8FC;
          // padding: 0.31rem 0;
          // border-radius: 0.08rem;
          // margin: 0.42rem 0 0.4rem;
          margin: 0.2rem 0 0.1rem;

          &>dt {
            padding-bottom: 0.28rem;
          }

          &>dd {
            padding: 0 0.44rem;
            border-top: 0.02rem solid #fff;

            dl {
              margin-top: 0.38rem;

              dt {
                line-height: 0.36rem;
              }

              dd {
                b {
                  font-size: 0.4rem;
                }
              }
            }
          }
        }

        .symptom {
          line-height: 0.44rem;

          span {
            color: #a1a4b3;
          }
        }
      }
    }

    .illness {
      margin-top: 1rem;

      .sort {
        top: 0.05rem;
        // left: 0.31rem;
        line-height: 0.32rem;
        padding: 0 0.06rem;
        font-weight: 500;
        color: #777f8f;
        border: 0.01rem solid #d3dae3;
        border-radius: 0.02rem;
      }

      .van-image {
        position: relative;
        z-index: 1000;
        width: 0.72rem;
      }

      dt.devg {
        span.text-c {
          display: block;
          text-align: left;
          // padding-left: 2.4rem;
          padding-left: 2rem;
        }
      }

      dd {
        .van-image {
          margin-top: 0.12rem;
        }
      }
    }

    // 體型改善
    .bodily-improve {
      &>div {
        margin-bottom: 0.64rem;

        &>.van-image {
          margin: 0.4rem 0 0.48rem;
        }

        &>div.qjc-clearfix {
          background-color: #f5f8fc;
          border-radius: 0.08rem;
          padding: 0.24rem 0;

          dl {
            width: 50%;

            &.qjc-fl {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 0.12rem;
                right: 0;
                bottom: 0.12rem;
                width: 0.02rem;
                background-color: #9ea5c9;
              }
            }

            dd {
              .van-image {
                position: relative;
                margin-top: 0.1rem;
                z-index: 10000;
              }
            }
          }
        }
      }
    }

    // 飲食建議
    .diet-advice {
      margin-top: 1.12rem;

      .module-title {
        margin-bottom: 0.5rem;
      }

      &>div {
        height: 1.12rem;
        line-height: 0.3rem;
        background-color: #f5f8fc;
        border-radius: 0.08rem;
        padding: 0.2rem 0;
        margin-bottom: 0.16rem;

        &.diet-advice-title {
          height: 1.28rem;
          padding: 0.28rem;

          dt {
            margin-bottom: 0.12rem;
          }
        }

        .qjc-fl {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0.08rem;
            right: 0;
            bottom: 0.08rem;
            width: 0.02rem;
            background-color: #9ea5c9;
          }
        }

        dt {
          margin-bottom: 0.08rem;
        }

        dd {
          .van-image {
            position: relative;
            margin-left: 0.1rem;
            z-index: 10000;
          }
        }

        dd.maintain {
          b {
            color: #6a7180;
            font-size: 0.3rem;
          }
        }

        &>div {
          display: flex;
          align-items: center;
          justify-content: center;

          .van-image {
            margin-right: 0.15rem;
          }

          dl {
            min-width: 1.2rem;
          }
        }
      }

      .explain {
        line-height: 0.36rem;
      }
    }

    // 醫療費用預測
    .cost {
      margin-top: 0.64rem;

      .module-title::after {
        width: 3.42rem;
      }

      .van-image {
        margin: 0.48rem auto;
      }

      dl {
        background-color: #f5f8fc;
        line-height: 0.44rem;
        padding: 0.32rem 0;

        dt {
          color: #6a7180;
          margin-bottom: 0.12rem;
        }
      }
    }
  }

  //過渡掃描
  .load {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #5c77ef url(../../assets/images/experience_load_bg.png) no-repeat center center;
    background-size: 12.42rem 26.88rem;
    z-index: 99999;

    &>div {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);

      &>.van-image {
        position: absolute;
      }

      .img1 {
        top: 0.6rem;
        right: 0.32rem;
      }

      .img2 {
        top: 8.95rem;
        right: 0.26rem;
      }

      .img3 {
        top: 4.07rem;
        right: 0.32rem;
      }

      .img4 {
        top: 0.59rem;
        left: 0.32rem;
      }

      .img5 {
        top: 8.43rem;
        left: 0.34rem;
      }



      .person {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.31rem;
        height: 8.7rem;
        background: url(../../assets/images/experience_load_person_border.png) no-repeat;
        background-size: 100% 100%;
        transform: translateX(-50%);

        .border {
          position: relative;
          width: 2.73rem;
          height: 7.96rem;
          z-index: 10;
          overflow: hidden;
        }

        .person-bg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .line {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          animation: scan 1.5s linear infinite alternate;
          z-index: 2;

          @keyframes scan {
            from {
              top: 0;
            }

            to {
              top: 100%;
            }
          }
        }
      }

      .progress {
        position: absolute;
        top: 10.25rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        width: 6.78rem;
        height: 1.38rem;
        background: url(../../assets/images/experience_load_progress.png) no-repeat;
        background-size: 100% 100%;
        overflow: hidden;

        dl {
          position: relative;
          width: 100%;
          color: #f5f5fa;
          z-index: 10;

          dt {
            font-size: 0.56rem;
            font-weight: 500;
            line-height: 0.56rem;
          }

          dd {
            line-height: 0.24rem;
            margin-top: 0.08rem;
          }
        }

        .van-image {
          position: absolute;
          top: 0;
          left: 0;
          margin-left: -100%;
          z-index: 9;
        }
      }
    }
  }

  //長按存圖
  .saveLong {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 9999;
  }

  // 瞭解更多
  .know-more {
    margin-top: 1.12rem;

    .price {
      top: 2.37rem;
      left: 1.1rem;
      color: #f66a6a;
      font-weight: 500;
      line-height: 0.28rem;
      z-index: 10001;

      span {
        margin-left: -0.04rem;
      }
    }

    .van-image {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10000;
    }
  }

  // 彈窗
  .more {
    background-color: transparent;
    z-index: 999999 !important;

    .price {
      top: 2.4rem;
      left: 1rem;
      color: #f66a6a;
      line-height: 1;

      span {
        margin-left: -0.03rem;
      }
    }

    &>.van-image {
      margin-top: 0.64rem;
    }
  }

  .footer {
    font-size: 0.2rem;
    line-height: 0.2rem;
    color: #6a7180;
    text-align: center;
    margin-top: 0.64rem;
  }
}



.bmi_tips {
  font-weight: 400;
  line-height: 0.44rem;
  // color: #40444D;
  font-size: 0.28rem;
  margin-top: 0.3rem;
  text-align: left;
}

.branch {
  width: 100%;
  padding: 0.24rem;
  background-color: #fff;
}

.branch_score {
  position: relative;
  margin-bottom: 0.16rem;
}

.branch_score .branch_content {
  padding-top: 0.88rem;
  border-radius: 0.12rem;

  .arrow {
    position: absolute;
    top: -0.02rem !important;
    left: 0.86rem !important;
    z-index: 4 !important;
    width: 0.4rem !important;
    height: 0.4rem !important;
    background-color: #fff !important;
    transform: rotateZ(45deg) translateX(-50%);
  }
}

.branch_item_title {
  position: absolute;
  top: 0;
  left: -0.02rem;
  display: flex;
  align-items: center;
  width: 2.2rem;
  height: 0.66rem;
  padding-bottom: 0.08rem;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  padding: 0 0.24rem 0.08rem;
  color: #fff;
  background: url(../../assets/images/result_score_title.png);
  background-size: 100% 100%;
  // box-shadow: 0px -2px 6px rgba(56, 81, 130, 0.25) inset;
}

.branch_sangao_title {
  position: absolute;
  top: 0;
  left: -0.02rem;
  display: flex;
  align-items: center;
  width: 4.9rem;
  height: 0.66rem;
  padding-bottom: 0.08rem;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  padding: 0 0.24rem 0.08rem;
  color: #fff;
  background: url(../../assets/images/score_title_bg2.png);
  background-size: 100% 100%;
}

.branch_dl_title {
  position: absolute;
  top: 0;
  left: -0.02rem;
  display: flex;
  align-items: center;
  width: 5.8rem;
  height: 0.66rem;
  padding-bottom: 0.08rem;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  padding: 0 0.24rem 0.08rem;
  color: #fff;
  background: url(../../assets/images/score_title_bg3.png);
  background-size: 100% 100%;
}

.score_wrap {
  display: flex;
  align-items: center;
}

.score_info_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 1.3rem;
  color: rgba(59, 69, 73, 1);
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 0.28rem;
  background: rgba(242, 247, 255, 1);
  border-radius: 0.12rem;
  text-align: left;
  padding: 0.24rem 0.32rem;
}

.score_info_top::before {
  content: "";
  width: 0;
  height: 0;
  border: 0.2rem solid rgba(242, 247, 255, 1);
  border-color: rgba(242, 247, 255, 1) transparent transparent transparent;
  position: absolute;
  bottom: -0.36rem;
  left: var(--infoLeft);
}

.score_info_top_info {
  display: flex;
  align-items: center;
  margin-left: 0.08rem;
}


.score_line {
  position: relative;
  width: 100%;
  height: 0.12rem;
  border-radius: 0.5rem;
  margin-top: 0.3rem;
  background-color: rgb(219, 228, 242);
  box-shadow: 0 0.2rem 0.2rem rgba(81, 159, 232, 0.15) inset;
}

.score_line::before {
  content: "0";
  position: absolute;
  top: 0.2rem;
  left: 0;
  color: rgba(108, 130, 168, 1);
  font-family: PingFang SC;
  font-size: 0.24rem;
  line-height: 0.22rem;
}

.score_one {
  position: absolute;
  width: 59%;
  height: 0.12rem;
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: rgba(254, 90, 110, 1);
  box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
}

.score_one_bg {
  position: absolute;
  width: 59%;
  height: 0.12rem;
  border-radius: 0.5rem 0 0 0.5rem;
  // background-color: rgba(254, 90, 110, 1);
  // box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
  // border-right: 0.08rem solid #fff;
}

.score_one_bg::after {
  content: "59";
  position: absolute;
  top: 0.2rem;
  right: -0.12rem;
  color: rgba(108, 130, 168, 1);
  font-family: PingFang SC;
  font-size: 0.24rem;
  line-height: 0.22rem;
}

.score_line_top {
  width: 100%;
  height: 0.12rem;
  border-radius: 0.5rem;
  background-color: rgb(219, 228, 242);
  box-shadow: 0 0.2rem 0.2rem rgba(81, 159, 232, 0.15) inset;
}

.score_d1 {
  position: absolute;
  left: 58%;
  width: 0.08rem;
  height: 0.12rem;
  background-color: #fff;
  z-index: 99;
}

.score_d2 {
  position: absolute;
  left: 88%;
  width: 0.08rem;
  height: 0.12rem;
  background-color: #fff;
  z-index: 99;
}

.score_circle1 {
  position: absolute;
  top: -0.06rem;
  left: 0;
  width: 0.22rem;
  height: 0.22rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0.02rem 0.04rem rgba(165, 209, 149, 1);
  z-index: 100;
}

.score_circle2 {
  position: absolute;
  top: -0.06rem;
  left: 0;
  width: 0.22rem;
  height: 0.22rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0.02rem 0.04rem rgba(223, 202, 149, 1);
  z-index: 100;
}

.score_circle3 {
  position: absolute;
  top: -0.06rem;
  left: 0;
  width: 0.22rem;
  height: 0.22rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0.02rem 0.04rem rgba(223, 171, 154, 1);
  z-index: 100;
}

.score_two {
  position: absolute;
  width: 30%;
  left: 59%;
  height: 0.12rem;
  // border-radius: 0.5rem 0 0 0.5rem;
  background-color: rgba(255, 204, 26, 1);
  box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
  // border-right: 0.08rem solid #fff;
}

.score_two_bg {
  position: absolute;
  width: 30%;
  left: 59%;
  height: 0.12rem;
}

.score_two_bg::after {
  content: "89";
  position: absolute;
  top: 0.2rem;
  right: -0.12rem;
  color: rgba(108, 130, 168, 1);
  font-family: PingFang SC;
  font-size: 0.24rem;
  line-height: 0.22rem;
}

.score_three_bg {
  position: absolute;
  width: 11%;
  left: 89%;
  height: 0.12rem;
  border-radius: 0 0.5rem 0.5rem 0;
}

.score_three {
  position: absolute;
  width: 11%;
  left: 89%;
  height: 0.12rem;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: rgb(31, 211, 169);
  box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
}

.score_three_bg::after {
  content: "100";
  position: absolute;
  top: 0.2rem;
  right: 0rem;
  color: rgba(108, 130, 168, 1);
  font-family: PingFang SC;
  font-size: 0.24rem;
  line-height: 0.22rem;
}

.score_text {
  color: rgba(106, 113, 128, 1);
  font-family: PingFang SC;
  font-size: 0.24rem;
  line-height: 0.36rem;
  text-align: left;
  margin-top: 0.5rem;
}


.branch_content {
  position: relative;
  width: 100%;
  padding: 0.24rem;
  border-radius: 0 0 0.12rem 0.12rem;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}

.branch_100 {
  width: 100%;
}

.branch_type_text {
  color: rgba(59, 69, 73, 1);
  font-family: PingFang SC;
  font-size: 0.28rem;
  line-height: 0.44rem;
  text-align: left;
  margin-top: 0.16rem;
}

.branch_type_c {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0 0.2rem;
  color: rgba(102, 129, 250, 1);
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 0.28rem;
  line-height: 0.32rem;
  border: 1px solid rgba(167, 183, 255, 1);
  border-radius: 50%;
}

.branch_yl {
  color: rgba(59, 69, 73, 1);
  font-family: PingFang SC;
  font-size: 0.28rem;
  line-height: 0.44rem;
  margin-bottom: 0.2rem;
  text-align: left;
}

.branch_yl span,
.branch_yjk span {
  color: rgba(102, 129, 250, 1);
  font-family: YouSheBiaoTiHei;
  font-size: 0.32rem;
  line-height: 0.44rem;
  font-weight: bold;
}

.branch_yjk {
  color: rgba(59, 69, 73, 1);
  font-family: PingFang SC;
  font-size: 0.28rem;
  line-height: 0.44rem;
  margin-bottom: 0.2rem;
  text-align: left;
}

.z_index9 {
  z-index: 101;
  margin-left: 0.1rem;
}

.score_tip_box {
  width: 6.1rem;
  left: -0.78rem;
  top: 0.6rem;
  padding: 0.24rem;
  color: rgba(59, 69, 73, 1);
  font-weight: 400;
  font-family: PingFang SC;
  font-size: 0.28rem;
  line-height: 0.44rem;
  border-radius: 0.12rem;
  box-shadow: 0.06rem 0.06rem 0.16rem rgba(47, 68, 87, 0.15);
  text-align: left;
}

.score_tip_span {
  display: block;
  font-weight: 500;
  color: rgba(59, 69, 73, 1);
  margin-top: 0.2rem;
}

.branch_wrap {
  padding: 0 0.24rem;
}

.branch_health_list {
  .HEA1 {
    background-color: #3fd84520;
    color: #3fd845;
  }

  .HEA2 {
    background-color: #fecd1c20;
    color: #fecd1c;
  }

  .HEA3 {
    background-color: #ff494920;
    color: #ff4949;
  }

  .health-card {
    position: relative;
    width: 100%;
    padding: 0.18rem 0.3rem;
    font-weight: bold;
    text-align: left;

    >div {
      padding: 0.12rem 0;
    }

    .card-label {
      font-size: 0.28rem;
    }

    .card-value {
      font-size: 0.4rem;
    }
  }
}

.branch_health_scale {
  transform: scale(1.12);
  font-weight: 500;
}

.score_val {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 0.48rem;
}

.xiao_icon {
  width: 0.56rem;
  height: 0.48rem;
}

.score_color1 {
  color: rgb(20, 203, 160);
}

.score_color2 {
  color: rgb(254, 152, 0);
}

.score_color3 {
  color: rgb(250, 102, 102);
}

.health-result {
  position: relative;
  width: 100%;
  padding: 0 0.24rem;
  font-size: .28rem;
  color: #32363e;

  >div {
    width: 100%;
  }



  .age-forecast-unlock {
    padding: 0 5%;
    overflow: hidden;

    >div {
      position: relative;
      width: 100%;
      height: 1.12rem;
      background-image: url(../../assets/images/report/unlock-remind-bg.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 .3rem;

      .unlock-label {
        font-size: .44rem;
        color: #5675ff;
        font-weight: bold;
      }

      .unlock-btn {
        position: relative;
        color: #fff;
        font-size: .24rem;
        display: flex;
        align-items: center;
        height: 0.6rem;
        border-radius: 0.6rem;
        overflow: hidden;

        >div {
          height: 100%;
          background-color: #7c94ff;
          padding: 0.16rem .22rem;

          &:first-child {
            margin-right: .04rem;

          }
        }

        span {
          font-size: .2rem;
        }
      }
    }


  }

  .health-result-title {
    font-size: .32rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: .24rem;
  }

  .health-result-subtitle {
    line-height: .4rem;
    font-weight: bold;
    text-align: left;
    padding: .24rem 0;
  }

  .health-result-content {
    margin-bottom: .24rem;
  }

  .tip-content {
    font-size: .24rem;

    >div {
      line-height: .42rem;
    }

    .tip-title {
      font-size: .32rem;
      font-weight: bold;
      margin: .12rem 0;

      >span {
        font-weight: bold;
      }
    }
  }

  .health-result-body {
    width: 100%;
    padding: .24rem;
    background-color: #fff;
    border-radius: .16rem;
    text-align: left;

    .result-body-type {
      color: #6681fa;
      font-size: .4rem;
      line-height: .64rem;
      font-weight: bold;
    }



    >div {
      line-height: .48rem;
    }

    .result-body-title {
      font-size: .32rem;
      font-weight: bold;
      margin-bottom: .12rem;
    }

    .result-body-tip {
      margin-top: .24rem;
      color: #8c919a;
    }
  }

  .advertisement-box {
    position: relative;
    width: 100%;
    margin-top: .24rem;

    img {
      width: 100%;
    }
  }

  .health-age-forecast {
    position: relative;
    width: 100%;




    >div {
      position: relative;
      width: 100%;
      margin-bottom: .24rem;


      >div {
        position: relative;
        width: 100%;

        &:first-child {
          background-color: #fff;

          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: .32rem;
          border-radius: .16rem;

          >div {
            &:first-child {
              border-right: .02rem solid #00000030;
            }
          }
        }

        >div {
          flex: 1;

          .label {
            font-size: .28rem;
          }

          .value {
            font-size: .4rem;
            color: #6681fa;

            >span {
              font-size: .24rem;
            }

            >img {
              width: .48rem;
              height: 0.48rem;
            }
          }


        }
      }
    }


  }

  .health-improve-plan {

    .improve-plan-box {
      position: relative;
      width: 100%;

      >div {}




    }

    .improve-plan-before-after {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      .arrow {
        flex: 2;

        img {
          width: .6rem;
          height: .5rem;
        }
      }

      .plan {
        flex: 3;

        .label {
          color: #8c919a;
        }

        .value {
          color: #ff627b;
          font-size: .4rem;

          span {
            font-size: .24rem;
          }


        }
      }
    }

    .health-value-card {
      position: relative;
      width: 100%;
      border-radius: .16rem;
      background-color: #fff;
      height: 1.52rem;
      padding: .24rem;


      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .label {
        color: #8c919a;
        line-height: .48rem;
      }

      .value {
        height: .48rem;
        display: flex;
        align-items: center;

        img {
          width: .48rem;
          height: .48rem;
        }
      }
    }
  }


  .health-result-paragraph {
    color: #32363e;
    line-height: .4rem;
    margin: .12rem 0;
    text-align: left;

    span {
      color: #3a3a3a;
    }

  }

  .health-result-system-plan {
    margin-top: .24rem;

    >div {
      position: relative;
      width: 100%;
    }

    .system-plan-box {
      background-color: #fff;
      border-radius: .16rem;
      margin-bottom: .24rem;

      >div {
        position: relative;
        width: 100%;
      }

      .header {
        height: .92rem;
        display: flex;
        align-items: center;
        padding: 0 .26rem;
        border-bottom: 1px solid #00000014;

        .rank {
          padding: 0 .08rem;
          border: 1px solid #979797;
          font-size: .12rem;
          line-height: .4rem;
          margin-right: .24rem;

          span {
            color: #6882fb;
            font-weight: bold;
          }
        }

        .title {
          font-size: .32rem;
          font-weight: bold;
        }
      }

      .system-plan-content {

        padding: .24rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        >div {
          position: relative;
          width: 100%;
        }

        .plan-content-text {
          font-size: .32rem;
          color: #323232;
        }

        .plan-content-chart {
          height: 5rem;
        }

        .system-plan-ai-tip {
          display: flex;
          align-items: flex-start;
          margin: .12rem 0;

          .ai-pic {
            flex: 0 0 auto;
            width: 0.72rem;
            height: .72rem;
            margin-right: .2rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .ai-content {
            flex: 1;
            padding: .12rem;
            border-radius: .2rem;
            border: 1px solid #6882fb;

            .ai-text {
              width: 100%;
              line-height: .48rem;
              text-align-last: left;
              font-weight: bold;

              span {
                color: #6882fb;
              }
            }

            .ai-btn {
              width: fit-content;
              font-size: .28rem;
              line-height: .4rem;
              padding: .08rem .26rem;
              border-radius: 2rem;
              background-image: linear-gradient(to right, #b49cfd, #336ef7);
              color: #fff;
              font-weight: bold;
              margin: auto;
            }
          }
        }
      }
    }
  }

  .health-future-medical {
    position: relative;
    width: 100%;
    margin-top: .24rem;

    .health-future-chart-box {
      position: relative;
      width: 100%;
      height: 6rem;

      #health-future-chart {
        width: 100%;
        height: 100%;
      }
    }

  }


}

.tip-content-tag {
  font-weight: bold;
}


.unlock-risk-content {
  position: relative;
  width: 6.16rem;
  height: 6rem;
  background-image: url(../../assets/images/unlock-bg.png);
  background-repeat: no-repeat;
  background-size: contain;

  background-position: center;
  padding: .44rem .64rem;
  font-size: .36rem;

  >div {
    position: relative;
    text-align: left;
  }

  .unlock-risk-title {
    position: relative;
    width: fit-content;
    font-weight: 500;
    color: #404e90;
    line-height: .5rem;
    z-index: 2;
    margin-bottom: .34rem;

    &::after {
      content: "";
      width: 100%;
      height: .16rem;
      background-color: #91A5FD;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .unlock-risk-desc {
    width: fit-content;
    padding: .04rem .18rem;
    background-color: #91A5FD;

    color: #fff;
    margin-bottom: 0.12rem;
  }

  .unlock-risk-price {
    margin-top: .24rem;
    margin-bottom: .32rem;

    .now {
      font-size: .4rem;
      font-weight: bold;
      color: #FF533D;
    }

    .pre {
      font-size: .22rem;
      color: #8C919A;

      span {
        text-decoration: line-through;
      }
    }
  }

  .unlock-risk-btn {
    color: #fff;
    font-weight: bold;
    border-radius: 1rem;
    width: fit-content;
    padding: .14rem .32rem .18rem;
    background-image: linear-gradient(to bottom, #7C94FF, #6681FA);
  }
}

.unlock-forecast-content {
  position: relative;
  width: 6.16rem;
  height: 6.84rem;
  background-image: url(../../assets/images/jkzc/forecast-popup-bg.png);
  background-repeat: no-repeat;
  background-size: contain;

  background-position: center;
  padding: .4rem .32rem;
  font-size: .36rem;
  background-color: transparent;
  border-radius: .18rem;

  .unlock-forecast-title {
    font-size: .36rem;
    text-align: left;
    padding: .24rem 0;
  }

  .unlock-forecast-desc {
    margin: .24rem 0;
    width: 100%;
    height: 1.28rem;
    font-size: .32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .38rem;
    background-image: linear-gradient(to bottom, #D1E3FF, #E4E9FF);
    border-radius: .16rem;

    >div {

      &:first-child {
        color: #2B4EEB;
      }

      &:last-child {
        color: #6681FA;
      }
    }
  }

  .unlock-forecast-detail {
    position: relative;
    width: 100%;
    border-radius: .16rem;
    background-image: linear-gradient(to bottom, #D1E3FF, #E4E9FF);
    border: 0.04rem solid #6681FA;
    display: flex;

    >div {
      position: relative;
      flex: 1;
    }

    .cate {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-bottom: .12rem;

      >div {
        width: 100%;
      }

      .cate-name {
        font-size: .3rem;
        font-weight: bold;
        color: #2B4EEB;
        padding: .04rem .16rem;
        background-color: #C5DAFF;
        margin: 0.08rem;
        width: fit-content;
        margin-left: .12rem;
      }

      .cate-title {
        padding: .18rem 0;

        >div {
          color: #fff;
          background-image: linear-gradient(to bottom, #FD8373, #FF7361);
          font-size: .4rem;
          font-weight: bold;
          padding: .08rem .18rem .12rem .26rem;
          margin-left: -.04rem;
        }
      }
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .price-hot {
        width: 1.54rem;
        height: 1.54rem;
      }

      .now {
        font-size: .4rem;
        font-weight: bold;
        color: #FF533D;
      }

      .pre {
        font-size: .22rem;
        color: #8C919A;

        span {
          text-decoration: line-through;
        }
      }
    }
  }
}

.unlock-close {
  position: absolute;
  bottom: -1rem;
  left: calc(50% - 0.43rem);
  width: 0.86rem;
  height: 0.86rem;
}
</style>
