<template>
  <div class="body-commit-card">
    <div class="body-commit-card-header-title">{{ title }}</div>
    <div class="body-commit-card-header-subtitle">你的{{ name }}:<span>{{ value }}</span></div>
    <div class="body-commit-card-header-range">
      <div class="range-label">與臨床標準比</div>
      <div class="range-chart">

        <div class="range-chart-pointer" :style="pointerStyle">
          <div class="pointer-info">
            <div class="pointer-value">{{ value }}</div>
            <div class="pointer-tag" :style="`background-color:${pointerColor};`" v-if="valTag">{{ valTag }}</div>
          </div>
        </div>
        <div v-for="item in range" class="range-chart-item" :key="item.key" :data-max="item.max"
          :style="`background-color:${item.color};`"></div>
      </div>
    </div>
    <div class="body-commit-card-header-tip">
      <slot name="tip"></slot>

    </div>
  </div>
</template>

<script>
export default {
  name: "bodyCommitCard",
  data () {
    return {
      pointerColor: "#000",
      valTag: ''
    }
  },
  computed: {
    pointerStyle () {
      const style = {
        'background-color': '#fff',
        left: 0,
        color: '#000',
      }
      let pos = ""
      this.range.forEach((item, index) => {
        if (this.value >= item.min && this.value < item.max) {
          style['background-color'] = item.color
          style['color'] = item.color
          this.pointerColor = item.color + 20
          let rate = 1 / this.range.length * 100
          pos = index * rate
          let offset = (this.value - item.min) / (item.max - item.min) * rate
          this.valTag = item.name
          style.left = pos + offset + "%"
        }
      })

      const sty = Object.entries(style).map(([key, value]) => `${key}:${value};`).join('')
      return sty
    },

  },
  props: {
    list: {
      default: () => [],
      type: Array
    },
    range: {
      default: () => [],
      type: Array
    },
    value: {
      default: 0,
      type: Number
    },
    name: {
      default: "",
      type: String
    },
    tag: {
      default: "",
      type: String
    },
    title: {
      default: "",
      type: String
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.body-commit-card {
  position: relative;
  width: 100%;
  padding: .26rem;
  background-color: #fff;
  border-radius: .16rem;
  text-align: left;

  >div {
    position: relative;
    width: 100%;
  }

  .body-commit-card-header-title {
    position: relative;
    font-size: .32rem;
    font-weight: bold;

  }

  .body-commit-card-header-subtitle {
    font-size: .24rem;
    margin-top: .18rem;

    >span {
      font-size: .32rem;
      font-weight: bold;

    }
  }

  .body-commit-card-header-range {
    display: flex;
    align-items: flex-end;
    height: 1.6rem;
    padding: .4rem 0;


    >div {
      flex: 1;
      height: .4rem;

      &:first-child {
        flex: 0 0 auto;
        white-space: nowrap;
        padding-right: .24rem;
      }


    }

    .range-chart {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .range-chart-pointer {
        position: absolute;
        top: 0rem;
        width: .12rem;
        height: .22rem;
        // clip-path: polygon(0 0 , 0 60%,50% 100% , 100% 60%, 100% 0);
        border-radius: 0;
        z-index: 5;

        .pointer-info {
          position: absolute;
          top: -.6rem;
          left: -.6rem;
          white-space: nowrap;
          font-size: .32rem;
          display: flex;
          align-items: center;
          z-index: 8;

          .pointer-value {
            color: #000;
            font-size: .32rem;
            font-weight: bold;
            padding: .08rem;
          }

          .pointer-tag {
            font-size: .24rem;
            font-weight: normal;
            padding: .1rem;
            background-color: attr(data-color);
          }


        }
      }

      .range-chart-item {
        &::after {
          content: attr(data-max);
          position: absolute;
          bottom: -.36rem;
          right: -.2rem;
        }

        &:last-child::after {
          content: "";
          width: 0;
          height: 0;
          display: none;
        }
      }


      >div {
        position: relative;
        flex: 1;
        height: .12rem;
        border-radius: .12rem;
      }
    }
  }

  .body-commit-card-header-tip {
    border-top: 1px solid #00000030;
    padding-top: .24rem;
  }
}
</style>